import logo from "../assets/ryzant.png"

const Navbar = () => {
    return (
        <div className="flex items-center py-2 bg-white justify-center sticky top-0">
            <div className="mr-auto self-start">
                <img src={logo} width={100} alt="Logo" />
            </div>
            <a href="#home" className="mx-4">
                Home
            </a>
            <a href="#about" className="mx-4">
                About
            </a>
        </div>
    )
}

export default Navbar;