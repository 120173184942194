import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";

const App = () => {
  return (
    <div className="flex flex-col">
      <Navbar/>
     <Home/>
      <About/>
    </div>
  );
}

export default App;
