const Home = () => {
    return (
        <div id="home" className=" flex-col py-40 text-center h-screen">
            <h1 className="text-7xl font-SfDisplay py-4 bg-gradient-to-r from-ryzant-red to-red-400 inline-block text-transparent bg-clip-text">Coming Soon!</h1>
            <h3 className="text-lg my-0">A Social Media App for Realtors</h3>


            <p className="text-lg">Scroll down for more information on Ryzant.</p>
        </div>
    )
}

export default Home;