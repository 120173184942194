import Diagram from "../assets/Diagram1.png";
import Diagram2 from "../assets/Diagram2.png";
import Diagram3 from "../assets/Diagram3.png";
const About = () => {
    return (
        <div id="about" className="flex flex-col h-screen">
            <div className="flex-1">
                <div className="m-4 border-2 flex-1 hover:border-red-400 rounded-lg">
                    <h3 className=" text-4xl text-center font-semibold p-8 text-red-500">WHAT IS IT?</h3>
                    <p className="p-4">Ryzant is a mobile application with a clear mission: to build a platform exclusively for Real Estate Agents. The goal is simple,
                        to give realtors a place where they can network effectively and display their property listings.
                        In an industry where such a platform does not exist, Ryzant will exist to help Realtors and boost their credibility.</p>
                </div>
                <section className="m-4 flex-1 hover:border-red-400 border-2 rounded-lg">
                    <h3 className="text-4xl text-center font-semibold p-8 text-red-500">HOW?</h3>
                    <p className="p-4">Ryzant is a mobile application created using React Native making the application cross platform meaning it will
                        be available in both The App Store and Play Store. Its backend is constructed using Node.js. Ryzant follows a MVC design pattern in its backend allowing for cleaner
                        and maintainable code. Both the backend and Frontend are written in Javascript making it easy to maintain. They are also easy to use. Ryzant also uses Firebase Realtime Database and Amazon AWS S3.
                        These cloud platforms take care of images and messages. Ryzant plans on going live in the future using AWS EC2 for its backend.
                    </p>
                </section>
            </div>
            <div className="flex-1">
                <div className="m-4 border-2 hover:border-red-400 flex-1 rounded-lg">
                    <h3 className=" text-4xl text-center font-semibold p-8 text-red-500">WHAT DOES IT DO?</h3>
                    <p className="p-4">Ryzant has 5 main functions, message realtors, post listings, search realtors, and create accounts. A Realtor is able to register a realtor account and post listings
                    potential buyers are also allowed to create accounts and search for realtors to follow and message. A feed is available to all buyers showcasing the active listings. Non-function requirements for this project
                    are ensuring messages are sent without delay and good security measures such as encrypted passwords.
                    </p>
                </div>
                <div className="m-4 border-2  hover:border-red-400 flex-1 rounded-lg">
                    <h3 className=" text-4xl text-center font-semibold p-8 text-red-500">RISKS AND CHALLENGES?</h3>
                    <p className="p-4">Ryzant had alot of challenges and risks. The main challenge encountered was some of the tech stack, I had to learn AWS S3, Firebase, and React Native
                    These were new technologies to me, and I had to find a good platform for messaging that would not kill the phone battery. I ended up finding Firebase Realtime Database which
                    was used in many applications and had a good reputation. To overcome these risks, alot of research was needed to see what could work and what wouldnt work. The risk management plan
                    was to have everything planned and settled before development began to prevent last minute changes.
                    </p>
                </div>
                <div className="m-4 border-2 hover:border-red-400 flex-1 rounded-lg">
                    <h3 className=" text-4xl text-center font-semibold p-8 text-red-500">ISSUES?</h3>
                    <p className="p-4">Pending issues within Ryzant are mostly UI issues, there is certain spacing issues when editing listings, loading skeletons have issues in maintaining the color scheme of the app
                    resulting in two colors. The home feed does not update whenever a user is followed, and cache does not erase when a new user is logging in resulting in incorrect information.
                    </p>
                </div>
                <div className="flex justify-between items-start flex-row">
                    <div className="m-4 border-2 hover:border-red-400 rounded-lg p-4">
                        <p className="text-2xl text-center">High Fidelity Diagram</p>
                        <img src={Diagram} width={300} height={300}/>
                    </div>
                    <div className="m-4 border-2 hover:border-red-400 rounded-lg p-4">
                        <p className="text-2xl text-center">Physical Diagram</p>
                        <img src={Diagram2} width={300} height={300}/>
                    </div>
                    <div className="m-4 border-2 hover:border-red-400 rounded-lg p-4">
                        <p className="text-2xl text-center">Wireframes</p>
                        <img src={Diagram3} width={300} height={300}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;